import React, { useContext, useEffect, useState } from 'react'
import Head from 'shared/components/Head'
import Footer from 'components/templates/premium/t/Sections/Footer'
import ModelShareData from 'shared/components/SharePlayuModal'
import LogoPlayUImg from 'assets/images/logo_playu_black-big.png'
import { BaseContext, BaseContextProvider } from 'shared/context/BaseContext'
import { BasicTemplates } from 'utils/constants'
import { BasicUser } from 'models/basic-user'
import { getImgSvgByName } from 'utils/icons'
import { getTemplateSettings, TemplateSettings } from 'utils/template'
import { SCContainer, SCMenu, SCDetails, SCMain, SCNavBar, SCSocialNetworks } from './styles'
import 'aos/dist/aos.css'

interface LayoutContainerProps {
  template: BasicTemplates
  user: BasicUser
}
interface LayoutProps extends LayoutContainerProps {
  palette: TemplateSettings['palette']
}

const cssScripts = [
  {
    href: 'https://fonts.googleapis.com/css?family=Poppins:500&display=swap',
    rel: 'stylesheet',
  },
  {
    href: 'https://fonts.googleapis.com/css?family=Raleway:300&display=swap',
    rel: 'stylesheet',
  },
]

const Layout: React.FC<LayoutProps> = ({ palette, user }: Omit<LayoutProps, 'title'>) => {
  const { setShowShareDataModal } = useContext(BaseContext)
  const [isLoaded, setIsLoaded] = useState(false)

  useEffect(() => {
    setTimeout(() => {
      setIsLoaded(true)
      window.scrollTo(0, 0)
    }, 500)
  }, [])

  const handleLogoClicked = (): void => {
    setShowShareDataModal({ show: true })
  }

  return (
    <SCContainer isLoaded={isLoaded}>
      <SCNavBar>
        <SCMenu palette={palette} />
      </SCNavBar>
      <SCMain>
        <SCDetails>
          <div className="playu-main-icon">
            {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
            <img src={LogoPlayUImg} alt="Comparte tu PlayU" onClick={handleLogoClicked} />
          </div>

          <div className="header-content-box">
            {user.avatarUrl && (
              <div
                className="avatar-image rounded-circle"
                style={{
                  backgroundImage: `url(${user.avatarUrl})`,
                }}
              />
            )}
            <div className="user-name">{user.nameToDisplay}</div>
            <div className="user-job">{user.jobs[0].title}</div>
          </div>
        </SCDetails>

        <SCSocialNetworks>
          {user.contact.socialMedia
            .filter(sm => !sm.skip)
            .map(sm => {
              return (
                <a key={sm.id} href={sm.link} target={sm.link === '#' ? '_self' : '_target'}>
                  <img className="icon-sm" src={getImgSvgByName(sm.type)} alt={sm.type} />
                </a>
              )
            })}
        </SCSocialNetworks>
      </SCMain>
      <ModelShareData user={user} />
      <Footer />
    </SCContainer>
  )
}

const LayoutContainer: React.FC<LayoutContainerProps> = (props: LayoutContainerProps) => {
  const { user, template } = props
  const settings = getTemplateSettings(template)

  return (
    <BaseContextProvider values={{ settings }}>
      <Head
        link={cssScripts}
        titleTemplate={user.metadata.pageTitle}
        hidePrefix={user.metadata.hideDefaultTitlePrefix}
      />
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <Layout {...props} palette={settings.palette} />
    </BaseContextProvider>
  )
}
export default LayoutContainer
