import { keyframes } from '@emotion/react'
import styled from '@emotion/styled'
import { PaletteColors, ViewPorts } from 'assets/theme'
import Menu from 'shared/components/Menu'

export const slashAnimation = keyframes`
    0% {
        opacity: 1;
        z-index: 2;
    }
    100% {
        opacity: 0;
        z-index: 0;
    }
`

export const scaleAnimation = keyframes`
    0% {
        transform: scale(0.6);
    }
    100% {
        transform: scale(1);
    }
`

interface SCContainerProps {
  isLoaded: boolean
}

export const SCContainer = styled.div<SCContainerProps>`
  width: 100%;
  background: ${PaletteColors.white};
  visibility: ${({ isLoaded }) => (!isLoaded ? 'hidden' : 'visible')};
`

export const SCNavBar = styled.div`
  height: 50px;
  width: 100%;
  z-index: 10;
  top: 0;
`

export const SCMenu = styled(Menu)`
  position: absolute;
  right: 20px;
  top: 160px;
  z-index: 2;
`

export const SCMain = styled.div`
  min-height: calc(100vh - 134px);
  overflow: hidden;
  animation: ${scaleAnimation} 1.5s ease;
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
`

export const SCDetails = styled.div`
  height: auto;
  width: 100%;
  float: left;
  display: flex;
  align-items: center;
  justify-content: center;
  color: whitesmoke;
  font-family: raleway;
  font-weight: 400;
  letter-spacing: 1px;
  flex-direction: column;
  font-size: 50px;
  z-index: 1;

  & .playu-main-icon {
    margin-bottom: 22px;
    & img {
      cursor: pointer;
      width: 50px;
      height: 50px;
      margin: 0 auto;
      position: relative;
    }
  }

  & .header-content-box {
    text-align: center;
    height: auto;
    width: auto;

    & .avatar-image {
      margin: 0 auto;
      width: 160px;
      height: 160px;
      background-position: center;
      background-size: cover;
    }

    & .user-name {
      font-weight: 700;
      font-family: poppins;
      font-size: 30px;
      color: #000000;
    }

    & .user-job {
      font-size: 22px;
      color: ${PaletteColors.black};
    }
  }
`

export const SCSocialNetworks = styled.div`
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 280px;
  display: flex;
  height: auto;
  flex-wrap: wrap;
  margin: 0 auto;
  padding-top: 20px;

  & a {
    text-align: center;

    & .icon-sm {
      margin: 8px;
      width: 90px;
      opacity: 1;
      transition: all 0.4s ease;

      &:hover {
        opacity: 1;
        transform: scale(1.1);
      }
    }
  }

  @media only screen and (max-width: ${ViewPorts.xsMin}) {
    padding-top: 20px;
  }
`
