import styled from '@emotion/styled'
import Background from 'assets/images/premium/p-03/background.jpg'
import { PaletteColors, ViewPorts } from 'assets/theme'
import { TemplateSettings } from 'utils/template'
import { PremiumTemplates } from 'utils/constants'
import Menu from 'shared/components/Menu'

interface SCContainerProps {
  isLoaded: boolean
}

export const SCContainer = styled.div<SCContainerProps>`
  font-family: 'Poppins', sans-serif;
  color: #232940;
  background: ${PaletteColors.white};
  line-height: 1.6;
  font-size: 14.4px;
  visibility: ${({ isLoaded }) => (!isLoaded ? 'hidden' : 'visible')};

  & .playu-main-icon {
    cursor: pointer;
    margin-bottom: 42px;

    & img {
      width: 50px;
      height: 50px;
      margin: 0 auto;
      position: relative;
    }
  }

  & .text-muted {
    color: #9090a0 !important;
  }

  & .font-size-17 {
    font-size: 17px;
  }

  & .font-weight-semibold {
    font-weight: 600;
  }

  & .text-white-70 {
    color: rgba(255, 255, 255, 0.7);
  }

  & .section {
    padding: 40px 0px;
  }

  & .bg-overlay {
    background-color: rgba(33, 37, 41, 0.7);
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  & a {
    text-decoration: none !important;
    outline: none;
  }

  & .arrow {
    & a {
      color: ${PaletteColors.white};
    }

    & h4 {
      font-size: 18px;
    }
  }

  @media (max-width: 576px) {
    & .title {
      font-size: 1.6rem;
    }
  }
`

export const SCNavBar = styled.div`
  /* display: flex;
    align-items: center;
    justify-content: flex-end; */
  position: absolute;
  height: 50px;
  width: 100%;
  z-index: 10;
`

export const SCMenu = styled(Menu)`
  position: absolute;
  right: 40px;
  top: 20px;

  @media (max-width: ${ViewPorts.smMax}) {
    right: 20px;
  }
`

interface SCProfileAreaProps extends TemplateSettings {
  template: PremiumTemplates
}

export const SCProfileArea = styled.div<SCProfileAreaProps>`
  height: 100vh;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  ${({ template }) => {
    let css = ''
    switch (template) {
      case PremiumTemplates.BaseTwo_01:
        css = `
        background-image: url(${Background});
        `
        break
      default:
        break
    }
    return css
  }}

  & .hero-content {
    z-index: 1;
    position: relative;

    & img {
      width: 160px;
      height: 160px;
      border: 4px solid rgba(255, 255, 255, 0.5);
    }
  }

  & .hero-3-title {
    position: relative;
    font-weight: 500;
    font-size: 3.5em;
    margin-top: 2.5rem;

    & .letter {
      display: inline-block;
      line-height: 1em;
      transform-origin: 0 0;
    }

    & .text-wrapper {
      position: relative;
      display: inline-block;
      overflow: hidden;
    }
  }

  & .enterprise-section {
    justify-content: center;
    display: flex;
    margin-bottom: 18px;

    & .logo {
      width: 85px;
      height: 85px;
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;

      &.square {
        border-radius: 10px;
      }

      &.rectangle {
        width: 200px;
        height: 60px;
        border-radius: 0;
      }

      &.circle {
        border-radius: 50%;
      }
    }
  }

  & .avatar-image {
    width: 160px;
    height: 160px;
    margin: 0 auto;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    border: 4px solid rgba(255, 255, 255, 0.5);
  }

  & .job-description {
    min-height: 30px;
  }

  & .social-list .social-link {
    width: 54px;
    height: 54px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${PaletteColors.white};
    font-size: 32px;
    margin: 0 6px;

    &:hover {
      text-decoration: none;
      color: ${({ palette }) => palette?.primary?.light || 'inherit'};
    }
  }

  @media (max-width: 1024px) {
    & .profile-section {
      padding: 100px 0px 120px;
    }
  }

  @media (max-width: 576px) {
    & .hero-3-title {
      margin-top: 1rem;
      font-size: 2rem;
    }
  }
`

export const SCServicesArea = styled.div<TemplateSettings>`
  & .service-box {
    transition: all 0.5s ease;
    padding: 30px;

    & .service-icon {
      margin: 0 auto;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: rgba(255, 77, 65, 0.07);
      width: 3.5rem;
      height: 3.5rem;
      color: ${({ palette }) => palette?.primary?.normal || 'inherit'};
      font-size: 26px;
      transition: all 0.5s ease;
    }

    &:hover .service-icon,
    &.active .service-icon {
      background-color: ${({ palette }) => palette?.primary?.normal || 'inherit'};
      color: ${PaletteColors.white};
    }

    & .description {
      & ul {
        text-align: left;
        list-style: inherit;
        & li {
          & p {
            margin: 0;
          }
        }
      }
    }
  }
`

export const SCContactArea = styled.div<TemplateSettings>`
  background-color: #f8f8f8;

  & .contact-header {
    margin-bottom: 20px;
  }

  & .contact-details {
    align-items: stretch;

    & .single-contact {
      background-color: ${PaletteColors.white};
      min-height: 120px;
      box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
      padding: 5%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      word-break: break-word;
      border-radius: 16px;

      & p {
        margin: 0;
      }

      & i {
        font-size: 30px;
        color: ${({ palette }) => palette?.primary?.normal || 'inherit'};
        margin-bottom: 6px;
      }

      & h2 {
        text-transform: uppercase;
        font-size: 16px;
      }

      & a {
        text-decoration: underline !important;
        color: inherit;
      }

      & .socials i {
        font-size: 14px;
        color: ${({ palette }) => palette?.primary?.normal || 'inherit'};
        margin: 0 10px;

        &:hover {
          cursor: pointer;
        }
      }
    }
  }

  @media only screen and (max-width: 767px) {
    & .contact-details {
      & > div {
        margin-bottom: 30px;
      }
    }
  }
`
