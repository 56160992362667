import styled from '@emotion/styled'
import { PaletteColors, ViewPorts } from 'assets/theme'
import Background02 from 'assets/images/basic/b-02/background.jpg'
import { BasicTemplates } from 'utils/constants'
import { TemplateSettings } from 'utils/template'
import Menu from 'shared/components/Menu'

interface SCContainerProps extends TemplateSettings {
  template: BasicTemplates
  isLoaded: boolean
}

export const SCContainer = styled.div<SCContainerProps>`
  font-family: 'Poppins', sans-serif;
  color: #232940;
  line-height: 1.6;
  font-size: 14.4px;
  visibility: ${({ isLoaded }) => (!isLoaded ? 'hidden' : 'visible')};

  ${({ template, palette }) => {
    let css = ''
    switch (template) {
      case BasicTemplates.BaseTwo_Pink:
        css = `background-color: ${palette?.primary?.normal || 'inherit'};`
        break
      case BasicTemplates.BaseTwo_Black:
        css = `background-color: #000;`
        break
      default:
    }

    return css
  }}

  & a {
    text-decoration: none !important;
    outline: none;
  }

  & h1,
  & h4 {
    font-family: 'Poppins', sans-serif;
  }

  & .text-white-70 {
    color: rgba(255, 255, 255, 0.7);
  }

  & .list-unstyled {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`

export const SCNavBar = styled.div`
  /* display: flex;
align-items: center;
justify-content: flex-end; */
  position: absolute;
  height: 50px;
  width: 100%;
  z-index: 10;
`

export const getMenuTopStyle = (selector: string): string => {
  if (typeof document !== `undefined` || typeof window !== `undefined`) {
    const el = document.querySelector<HTMLDivElement>(selector)
    return el ? `${el.offsetHeight + 54}px` : '0'
  }
  return '0'
}

interface SCMenuProps {
  top: string
}

export const SCMenu = styled(Menu)<SCMenuProps>`
  position: absolute;
  right: 20px;
  z-index: 2;
  top: ${({ top }) => top};
`

interface SCMainAreaProps extends TemplateSettings {
  template: BasicTemplates
}

export const SCMainArea = styled.section<SCMainAreaProps>`
  min-height: calc(100vh - 84px);
  padding: 0;
  display: flex;
  align-items: center;

  ${({ template, palette }) => {
    let css = ''
    switch (template) {
      case BasicTemplates.BaseTwo_Pink:
        css = `background-color:${palette?.primary?.normal || 'inherit'};`
        break
      case BasicTemplates.BaseTwo_Black:
        css = `background-image: url(${Background02});`
        break
      default:
    }

    return css
  }}

  & .hero-content {
    z-index: 1;
    position: relative;

    & .avatar-image {
      margin: 0 auto;
      width: 160px;
      height: 160px;
      border: 4px solid rgba(255, 255, 255, 0.5);
      background-position: center;
      background-size: cover;
    }

    & .hero-3-title {
      position: relative;
      font-weight: 500;
      font-size: 3.5em;

      & .text-wrapper {
        position: relative;
        display: inline-block;
        overflow: hidden;

        & .letter {
          display: inline-block;
          line-height: 1em;
          transform-origin: 0 0;
        }
      }
    }
  }

  @media (max-width: ${ViewPorts.xsMax}) {
    font-size: 3rem;
    padding: 42px 0;

    & .hero-3-title {
      font-size: 2.5rem !important;
    }
  }
`

export const SCSocialNetworks = styled.div<TemplateSettings>`
  & .social-list {
    & .social-link {
      background-color: #30364d;
      width: 54px;
      height: 54px;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      color: ${PaletteColors.white};
      font-size: 32px;
      margin: 0 6px;
    }

    &:hover {
      & .social-link {
        text-decoration: none;
        color: ${({ palette }) => palette?.primary?.hover || 'inherit'};
      }
    }
  }
`

export const SCIconSection = styled.div`
  margin-bottom: 20px;

  & .playu-main-icon {
    margin-bottom: 12px;
    & img {
      cursor: pointer;
      width: 50px;
      height: 50px;
      margin: 0 auto;
      position: relative;
    }
  }
`
