/* eslint-disable react/display-name */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useContext, useMemo, useState } from 'react'
import ModelShareData from 'shared/components/SharePlayuModal'
import { AvailableContentSections, PremiumTemplates } from 'utils/constants'
import { PremiumUser } from 'models/premium-user'
import Head from 'shared/components/Head/Head'
import { BaseContext, BaseContextProvider } from 'shared/context/BaseContext'
import PlayuButton from 'shared/components/PlayuButton'
import { Global } from '@emotion/react'
import { getTemplateSettings } from 'utils/template'
import { ColorSwitchProps } from 'shared/components/ColorSwitch/ColorSwitch'
import { Header, Footer, renderContent, Profile, SocialMedia, Contact, Service, ContentProps } from './Sections'
import { SCContainer, CSSBody } from './styles'

interface LayoutContainerProps {
  template: PremiumTemplates
  user: PremiumUser
  enableColorSwitch?: boolean
  onColorSwitchChanged?: ColorSwitchProps['onChange']
}

type LayoutProps = LayoutContainerProps

const cssScripts = [
  {
    href: 'https://fonts.googleapis.com/css2?family=Roboto&family=Sansita+Swashed:wght@300;400;500&display=swap',
    rel: 'stylesheet',
  },
]

const GlobalStyles: React.FC = () => {
  const { settings } = useContext(BaseContext)
  return <Global styles={CSSBody(settings?.palette)} />
}

const getContentByName = (
  { metadata, contact, services }: PremiumUser,
  template: PremiumTemplates,
  option: {
    label: string
    value: string
  }
): ((props: ContentProps) => React.ReactNode) | null => {
  const { label, value } = option

  const totalSections = metadata.sections.length

  const contents = {
    [AvailableContentSections.SocialMedia]: (props: ContentProps) => (
      <SocialMedia title={label} contentId={value} contact={contact} withNavigation={totalSections > 1} {...props} />
    ),
    [AvailableContentSections.Contact]: (props: ContentProps) => (
      <Contact
        title={label}
        contact={contact}
        contentId={value}
        withNavigation={totalSections > 1}
        noCardBorder={template === PremiumTemplates.TStyle_Black}
        {...props}
      />
    ),
    [AvailableContentSections.Services]: (props: ContentProps) => (
      <Service title={label} contentId={value} services={services} withNavigation={totalSections > 1} {...props} />
    ),
  }

  return contents[value as AvailableContentSections]
}

const Layout: React.FC<LayoutProps> = ({ user, template, enableColorSwitch, onColorSwitchChanged }: LayoutProps) => {
  const { settings } = useContext(BaseContext)

  const { dropdownMenu, contents } = useMemo(() => {
    const availableContents: ((props: ContentProps) => React.ReactNode)[] = []
    const availableMenus: { label: string; value: string; index: number }[] = []

    user.metadata.sections.forEach((option, index) => {
      availableMenus.push({
        ...option,
        index,
      })

      const contentByName = getContentByName(user, template, option)
      if (contentByName) {
        availableContents.push(contentByName)
      }
    })

    return {
      contents: availableContents,
      dropdownMenu: availableMenus,
    }
  }, [user])

  return (
    <>
      <Header
        user={user}
        settings={settings}
        enableColorSwitch={enableColorSwitch}
        onColorSwitchChanged={onColorSwitchChanged}
        menuOptions={{ dropdownMenu }}
      />
      {/* <Global styles={CSSArrowsNavigationAnimation} /> */}
      <SCContainer className="container">
        <Profile user={user} />
        <div>{renderContent(contents, settings?.behavior?.sections?.displayMode)}</div>
      </SCContainer>
      <PlayuButton customLogo={user.enterprise?.playuButtonImage} />
      <ModelShareData user={user} />
      <Footer metadata={user.metadata} />
    </>
  )
}

const LayoutContainer: React.FC<LayoutContainerProps> = (props: LayoutContainerProps) => {
  const {
    template: selectedTemplate,
    user: { metadata },
  } = props

  const [template, setTemplate] = useState<PremiumTemplates>(selectedTemplate)
  const settings = getTemplateSettings(selectedTemplate, metadata.theme)

  const handleColorSwitchChanged: ColorSwitchProps['onChange'] = ({ newTemplate }): void => {
    setTemplate(newTemplate as PremiumTemplates)
  }

  return (
    <BaseContextProvider values={{ settings }}>
      <GlobalStyles />
      <Head link={cssScripts} titleTemplate={metadata.pageTitle} hidePrefix={metadata.hideDefaultTitlePrefix} />
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <Layout {...props} template={template} onColorSwitchChanged={handleColorSwitchChanged} />
    </BaseContextProvider>
  )
}

export default LayoutContainer
