import React, { useEffect, useState } from 'react'
import { UserProfileType, BasicTemplates, PremiumTemplates, EnterpriseTemplates } from 'utils/constants'
import { BasicUser, BasicUserArgs } from 'models/basic-user'
import { graphql } from 'gatsby'
import BasicBaseTemplate from 'components/templates/basic/base/Layout'
import BasicBaseTwoTemplate from 'components/templates/basic/base-two/Layout'
import PremiumBaseTemplate from 'components/templates/premium/base/Layout'
import PremiumBaseTwoTemplate from 'components/templates/premium/base-two/Layout'
import PremiumTTemplate from 'components/templates/premium/t/Layout'
import EnterpriseTemplate from 'components/templates/enterprise/t/Layout'
import { PremiumUser, PremiumUserArgs } from 'models/premium-user'

const getUserData = (data: BasicUserArgs | PremiumUserArgs): BasicUser | PremiumUser => {
  let profileType: UserProfileType | null = null

  switch (data.template) {
    case BasicTemplates.Base_01:
    case BasicTemplates.BaseTwo_Pink:
    case BasicTemplates.BaseTwo_Black:
      profileType = UserProfileType.Basic
      break
    case PremiumTemplates.Base_01:
    case PremiumTemplates.Base_02:
    case PremiumTemplates.BaseTwo_01:
    case PremiumTemplates.TStyle_Black:
    case PremiumTemplates.TStyle_Bone:
    case PremiumTemplates.TStyle_Pink:
      profileType = UserProfileType.Premium
      break
    default:
      profileType = null
      break
  }

  return profileType === UserProfileType.Basic ? new BasicUser(data) : new PremiumUser(data as PremiumUserArgs)
}

const getLayout = (template: string, user: BasicUser | PremiumUser): React.ReactElement | null => {
  let chosenLayout: React.ReactElement | null = null

  switch (template) {
    case BasicTemplates.Base_01:
      chosenLayout = <BasicBaseTemplate user={user} template={template} />
      break
    case BasicTemplates.BaseTwo_Black:
    case BasicTemplates.BaseTwo_Pink:
      chosenLayout = <BasicBaseTwoTemplate user={user} template={template} footer={{ noBg: true, inverted: true }} />
      break
    case PremiumTemplates.Base_01:
    case PremiumTemplates.Base_02:
      chosenLayout = <PremiumBaseTemplate user={user as PremiumUser} template={template} />
      break
    case PremiumTemplates.BaseTwo_01:
      chosenLayout = <PremiumBaseTwoTemplate user={user as PremiumUser} template={template} />
      break
    case PremiumTemplates.TStyle_Black:
    case PremiumTemplates.TStyle_Bone:
    case PremiumTemplates.TStyle_Pink:
      chosenLayout = <PremiumTTemplate user={user as PremiumUser} template={template} />
      break
    case EnterpriseTemplates.MS_Normal:
      chosenLayout = <EnterpriseTemplate user={user as PremiumUser} template={template} />
      break
    default:
      chosenLayout = null
      break
  }

  return chosenLayout
}

interface PageProps {
  data: {
    contentfulUser: BasicUserArgs | PremiumUserArgs
  }
}

const TemplateGeneratorComponent: React.FC<PageProps> = ({ data: { contentfulUser } }: PageProps) => {
  const [user, setUser] = useState<PremiumUser | BasicUser | null>(null)

  useEffect(() => {
    if (contentfulUser) {
      const userData = getUserData(contentfulUser)
      setUser(userData)
    }
  }, [])

  return user ? getLayout(contentfulUser.template, user) : null
}

export const pageQuery = graphql`
  query ($id: String!) {
    contentfulUser(id: { eq: $id }) {
      id
      firstLastName
      secondLastName
      name
      nameToDisplay
      slug
      template
      businessProfileType
      enterprise {
        name
        logoShape
        logoImage {
          file {
            url
          }
        }
        playuButtonImage {
          file {
            url
          }
        }
      }
      metadata {
        pageTitle
        footer {
          isEnabled
          text
        }
        sections {
          label
          value
        }
        theme {
          behavior {
            header {
              edgeType
            }
            sections {
              displayMode
            }
          }
          palette {
            backgroundColor
            textColor
            header {
              backgroundColor
            }
            playuButton {
              backgroundColor
            }
            primary {
              normal
              light
              dark
              hover
            }
            secondary {
              normal
              light
              dark
              hover
              shadow
            }
          }
        }
      }
      avatarUrl {
        file {
          url
        }
      }
      jobs {
        id
        title
        enterprise {
          id
          name
          logoShape
          logoImage {
            file {
              url
            }
          }
        }
      }
      contact {
        id
        email
        phoneNumbers
        profileButtonLink
        addresses {
          id
          link
          description {
            description
          }
        }
        socialMedia {
          id
          link
          icon
          skip
          description {
            description
          }
          type
        }
      }
      services {
        id
        title
        description {
          raw
        }
        icon
      }
    }
  }
`

export default TemplateGeneratorComponent
