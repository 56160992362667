import Aos from 'aos'
import { PremiumTemplates } from 'utils/constants'

// let anime: any
let $: any
if (typeof document !== `undefined` || typeof window !== `undefined`) {
  $ = require('jquery')
  // eslint-disable-next-line import/newline-after-import
  ;(window as any).jQuery = $

  // anime = require('../../../../../static/js/anime.min.js')
  require('../../../../../static/js/particles.min.js')
}

const initJs = (template: PremiumTemplates): null => {
  if (typeof document === `undefined` || typeof window === `undefined`) {
    return null
  }

  // if (animateScaleRef) {
  //   const el = document.querySelector('.hero-3-title .letters')
  //   if (el && el.textContent) {
  //     el.innerHTML = el.textContent.replace(/\S/g, "<span class='letter'>$&</span>")
  //   }

  //   anime
  //     .timeline({ loop: true })
  //     .add({
  //       targets: '.hero-3-title .letter',
  //       rotateY: [-90, 0],
  //       duration: 1500,
  //       delay: (_: any, i: number) => 45 * i,
  //     })
  //     .add({
  //       targets: '.hero-3-title',
  //       opacity: 0,
  //       duration: 1500,
  //       easing: 'easeOutExpo',
  //       delay: 500,
  //     })
  // }

  if (template === PremiumTemplates.BaseTwo_01) {
    ;(window as any).particlesJS('home', {
      particles: {
        number: {
          value: 70,
          density: {
            enable: true,
            value_area: 800,
          },
        },
        color: {
          value: '#ffffff',
        },
        shape: {
          type: 'circle',
          stroke: {
            width: 0,
            color: '#000000',
          },
          polygon: {
            nb_sides: 5,
          },
          image: {
            src: 'img/github.svg',
            width: 100,
            height: 100,
          },
        },
        opacity: {
          value: 0.25,
          random: false,
          anim: {
            enable: false,
            speed: 1,
            opacity_min: 0.1,
            sync: false,
          },
        },
        size: {
          value: 4,
          random: true,
          anim: {
            enable: false,
            speed: 40,
            size_min: 0.1,
            sync: false,
          },
        },
        line_linked: {
          enable: false,
          distance: 100,
          color: '#ffffff',
          opacity: 0.4,
          width: 1,
        },
        move: {
          enable: true,
          speed: 3,
          direction: 'none',
          random: false,
          straight: false,
          out_mode: 'out',
          attract: {
            enable: false,
            rotateX: 600,
            rotateY: 1200,
          },
        },
      },
      interactivity: {
        detect_on: 'canvas',
        events: {
          onhover: {
            enable: false,
            mode: 'repulse',
          },
          onclick: {
            enable: false,
            mode: 'push',
          },
          resize: true,
        },
        modes: {
          grab: {
            distance: 400,
            line_linked: {
              opacity: 1,
            },
          },
          bubble: {
            distance: 400,
            size: 40,
            duration: 2,
            opacity: 8,
            speed: 3,
          },
          repulse: {
            distance: 200,
          },
          push: {
            particles_nb: 4,
          },
          remove: {
            particles_nb: 2,
          },
        },
      },
      retina_detect: true,
      config_demo: {
        hide_card: false,
        background_color: '#b61924',
        background_image: '',
        background_position: '50% 50%',
        background_repeat: 'no-repeat',
        background_size: 'cover',
      },
    })
  }

  Aos.init({
    once: true,
    easing: 'ease-in-out',
  })

  return null
}

export default initJs
