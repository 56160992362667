/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import Head from 'shared/components/Head'
import Footer from 'components/templates/premium/t/Sections/Footer'
import ModelShareData from 'shared/components/SharePlayuModal'
import { BaseContextProvider } from 'shared/context/BaseContext'
import { PremiumTemplates, SocialMedia } from 'utils/constants'
import { PremiumUser } from 'models/premium-user'
import Typed from 'typed.js'
import { formatPeruvianPhone, toHtmlFormat } from 'utils/format'
import { getIconClassByType, IconLib } from 'utils/icons'
import PlayuButton from 'shared/components/PlayuButton'
import { getTemplateSettings, TemplateSettings } from 'utils/template'
import { OptionMenu } from 'shared/components/Menu'
import initJs from './init'
import 'remixicon/fonts/remixicon.css'
import 'aos/dist/aos.css'
import { SCContainer, SCMenu, SCProfileArea, SCContactArea, SCServicesArea, SCNavBar } from './styles'

const menu = [
  {
    index: 0,
    label: 'Inicio',
    id: '#main',
  },
  {
    index: 1,
    label: 'Mis Servicios',
    id: '#services',
  },
  {
    index: 2,
    label: 'Mi Contacto',
    id: '#contact',
  },
]

const getResponsiveColumn = (len: number): string => {
  if (len === 1) {
    return 'col-md-12'
  }
  if (len === 2) {
    return 'col-md-6'
  }
  return 'col-md-4'
}

const getBodyClass = (template: PremiumTemplates): string => {
  let className = ''
  switch (template) {
    case PremiumTemplates.BaseTwo_01:
      className = 'pb2-01'
      break
    default:
      break
  }
  return className
}

interface LayoutContainerProps {
  template: PremiumTemplates
  user: PremiumUser
}
interface LayoutProps extends LayoutContainerProps {
  palette: TemplateSettings['palette']
}

const Layout: React.FC<LayoutProps> = ({ palette, template, user }: LayoutProps) => {
  const [isLoaded, setIsLoaded] = useState(false)

  useEffect(() => {
    initJs(template)

    setTimeout(() => {
      setIsLoaded(true)

      // eslint-disable-next-line no-new
      new Typed('.job-description .list', {
        strings: user.jobs.map(({ title }) => title),
        typeSpeed: 25,
        backSpeed: 20,
        backDelay: 5000,
        loop: user.jobs.length !== 1,
        showCursor: false,
      })
    }, 500)
  }, [])

  const handleNavigation = (e: React.MouseEvent<HTMLAnchorElement>, option: OptionMenu): void => {
    e.preventDefault()
    const el = document.querySelector(option.id!)
    if (el) {
      el.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      })
    }
  }

  return (
    <SCContainer isLoaded={isLoaded}>
      <SCNavBar>
        <SCMenu onSectionClick={handleNavigation} dropdownMenu={menu} palette={palette} />
      </SCNavBar>

      <SCProfileArea template={template} className="position-relative align-items-center d-flex" palette={palette}>
        <div id="home" className="bg-overlay" />
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-8">
              <div className="text-center mx-auto hero-content">
                {user.enterprise?.logoImage ? (
                  <div className="col-md-12 enterprise-section">
                    <div
                      className={`logo ${user.enterprise.logoShape}`}
                      style={{
                        backgroundImage: `url(${user.enterprise?.logoImage})`,
                      }}
                    />
                  </div>
                ) : null}

                {user.avatarUrl && (
                  <div
                    className="avatar-image rounded-circle"
                    style={{
                      backgroundImage: `url(${user.avatarUrl})`,
                    }}
                  />
                )}
                <h1 className="text-white hero-3-title">
                  <span className="text-wrapper">
                    <span className="letters">{user.nameToDisplay}</span>
                  </span>
                </h1>

                <h4 className="text-white-70 mb-4 job-description">
                  <span className="list" />
                </h4>

                {user.enterprise && <h4 className="font-weight-normal text-white-70 mb-4">{user.enterprise.name}</h4>}

                <div className="row justify-content-center" data-aos="fade-down" data-aos-delay="300">
                  <ul className="list-unstyled">
                    {user.contact.socialMedia
                      .filter(sm => !sm.skip)
                      .map(sm => (
                        <li key={sm.id} className="list-inline d-inline-block social-list">
                          <a
                            target={sm.link === '#' ? '_self' : '_target'}
                            className="list-inline-item rounded social-link"
                            rel="noreferrer"
                            href={sm.link}
                          >
                            <i className={sm.icon ?? getIconClassByType(sm.type as SocialMedia, IconLib.Remix)} />
                          </a>
                        </li>
                      ))}
                  </ul>
                </div>

                <div className="row justify-content-center arrow">
                  <a
                    className="list-inline-item rounded mr-2 social-link"
                    href="#services"
                    onClick={e => handleNavigation(e, { id: '#services' } as any)}
                  >
                    <h4>&#x25bc;</h4>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </SCProfileArea>

      <SCServicesArea className="section" id="services" palette={palette} data-aos="fade-down" data-aos-delay="200">
        <div className="container">
          <div className="row">
            <div className="col-12 mb-4" data-aos="fade-down" data-aos-delay="200">
              <h1 className="text-black mt-5 text-center">
                <span className="text-wrapper">
                  <span className="letters">Servicios</span>
                </span>
              </h1>
            </div>
          </div>

          <div className="row" data-aos="fade-down" data-aos-delay="400">
            {user.services.map(el => (
              <div key={el.id} className={`col-12 ${getResponsiveColumn(user.services.length)} pt-2`}>
                <div className="service-box rounded position-relative active text-center">
                  <div className="service-icon rounded">
                    <i className={el.icon} />
                  </div>

                  <h5 className="font-size-17 font-weight-semibold mt-4 pt-2">{el.title}</h5>
                  {el.description && (
                    <div
                      dangerouslySetInnerHTML={{ __html: toHtmlFormat(el.description) }}
                      className="description text-muted mt-3"
                    />
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      </SCServicesArea>

      <SCContactArea className="section " id="contact" palette={palette} data-aos="fade-down" data-aos-delay="200">
        <div className="container">
          <div className="contact-header" data-aos="fade-down" data-aos-delay="200">
            <div className="row">
              <div className="col-12 mb-4">
                <h1 className="text-black mt-5 text-center">
                  <span className="text-wrapper">
                    <span className="letters">Contácto</span>
                  </span>
                </h1>
              </div>
            </div>
          </div>
          <div className="row contact-details" data-aos="fade-down" data-aos-delay="500">
            <div className="col-md-4 col-sm-6">
              <div className="single-contact text-center">
                <i className="far fa-envelope" />
                <h2>Email</h2>
                <p>
                  <a target="_blank" rel="noreferrer" href={`mailto:${user.contact.email}`}>
                    {user.contact.email}
                  </a>
                </p>
              </div>
            </div>
            <div className="col-md-4 col-sm-6" data-aos="fade-down" data-aos-delay="600">
              <div className="single-contact text-center">
                <i className="fas fa-phone" />
                <h2>Whatsapp</h2>
                {user.contact.phoneNumbers.map(phone => (
                  <p key={phone}>
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href={`https://api.whatsapp.com/send?phone=${phone}&text=Hola%21%20Quisiera%20reservar%20una%20cita.`}
                    >
                      {formatPeruvianPhone(phone)}
                    </a>
                  </p>
                ))}
              </div>
            </div>
            <div className="col-md-4 col-sm-6" data-aos="fade-down" data-aos-delay="700">
              <div className="single-contact text-center">
                <i className="fas fa-map-marker-alt" />
                <h2>Dirección</h2>
                {user.contact.addresses.map(address => (
                  <p key={address.id}>
                    {address.link ? (
                      <a target="_blank" rel="noreferrer" href={address.link}>
                        {address.description}
                      </a>
                    ) : (
                      address.description
                    )}
                  </p>
                ))}
              </div>
            </div>
          </div>
        </div>
      </SCContactArea>

      <PlayuButton customLogo={user.enterprise?.playuButtonImage} />
      <ModelShareData user={user} />
      <Footer metadata={user.metadata} />
    </SCContainer>
  )
}

const LayoutContainer: React.FC<LayoutContainerProps> = (props: LayoutContainerProps) => {
  const { template, user } = props
  const settings = getTemplateSettings(template, user.metadata.theme)

  return (
    <BaseContextProvider values={{ settings }}>
      <Head
        titleTemplate={user.metadata.pageTitle}
        hidePrefix={user.metadata.hideDefaultTitlePrefix}
        bodyClass={[getBodyClass(template)]}
      />
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <Layout {...props} palette={settings.palette} />
    </BaseContextProvider>
  )
}
export default LayoutContainer
