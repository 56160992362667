/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext, useEffect, useRef, useState } from 'react'
import Head from 'shared/components/Head'
import Footer, { FooterProps } from 'components/templates/premium/t/Sections/Footer'
import ModelShareData from 'shared/components/SharePlayuModal'
import LogoPlayUImg from 'assets/images/logo_playu_white-big.png'
import { BaseContext, BaseContextProvider } from 'shared/context/BaseContext'
import { BasicTemplates, SocialMedia } from 'utils/constants'
import { BasicUser } from 'models/basic-user'
import { IconLib, getIconClassByType } from 'utils/icons'
import { getTemplateSettings, TemplateSettings } from 'utils/template'
import initJs from './init'
import 'remixicon/fonts/remixicon.css'
import { SCContainer, SCMenu, SCIconSection, SCMainArea, SCNavBar, SCSocialNetworks, getMenuTopStyle } from './styles'

const cssScripts = [
  {
    href: 'https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap',
    rel: 'stylesheet',
  },
]
interface LayoutContainerProps {
  template: BasicTemplates
  user: BasicUser
  footer?: FooterProps
}
interface LayoutProps extends LayoutContainerProps {
  palette: TemplateSettings['palette']
}

const Layout: React.FC<LayoutProps> = ({ user, footer, palette, template }: LayoutProps) => {
  const animateScaleRef = useRef<HTMLDivElement>(null)
  const { setShowShareDataModal } = useContext(BaseContext)
  const [isLoaded, setIsLoaded] = useState(false)
  const [menuTop, setMenuTop] = useState('0')

  useEffect(() => {
    initJs(animateScaleRef)
    setMenuTop(getMenuTopStyle('.avatar-image'))

    setTimeout(() => {
      setIsLoaded(true)
    }, 500)
  }, [])

  const handleLogoClicked = (): void => {
    setShowShareDataModal({ show: true })
  }

  return (
    <SCContainer isLoaded={isLoaded} template={template} palette={palette}>
      <SCNavBar>
        <SCMenu palette={palette} top={menuTop} />
      </SCNavBar>
      <SCMainArea id="home" template={template} palette={palette}>
        <div className="container">
          <SCIconSection className="row justify-content-center">
            <a className="playu-main-icon">
              {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
              <img src={LogoPlayUImg} alt="Comparte tu PlayU" onClick={handleLogoClicked} />
            </a>
          </SCIconSection>

          <div className="row justify-content-center">
            <div className="col-lg-8">
              <div className="text-center mx-auto hero-content">
                {user.avatarUrl && (
                  <div
                    className="avatar-image rounded-circle"
                    style={{
                      backgroundImage: `url(${user.avatarUrl})`,
                    }}
                  />
                )}
                <h1 className="text-white hero-3-title mt-5">
                  <span className="text-wrapper">
                    <span className="letters" ref={animateScaleRef}>
                      {user.nameToDisplay}
                    </span>
                  </span>
                </h1>
                <h4 className="font-weight-normal text-white-70 mb-4"> {user.jobs[0].title}</h4>

                <SCSocialNetworks className="row justify-content-center" palette={palette}>
                  <ul className="list-unstyled">
                    {user.contact.socialMedia
                      .filter(sm => !sm.skip)
                      .map(sm => (
                        <li key={sm.id} className="list-inline d-inline-block social-list">
                          <a
                            className="list-inline-item rounded social-link"
                            href={sm.link}
                            target={sm.link === '#' ? '_self' : '_target'}
                          >
                            <i className={sm.icon ?? getIconClassByType(sm.type as SocialMedia, IconLib.Remix)} />
                          </a>
                        </li>
                      ))}
                  </ul>
                </SCSocialNetworks>
              </div>
            </div>
          </div>
        </div>
      </SCMainArea>

      <ModelShareData user={user} />
      <Footer inverted={footer?.inverted} noBg={footer?.noBg} />
    </SCContainer>
  )
}

const LayoutContainer: React.FC<LayoutContainerProps> = (props: LayoutContainerProps) => {
  const { template, user } = props
  const settings = getTemplateSettings(template)

  return (
    <BaseContextProvider values={{ settings }}>
      <Head
        link={cssScripts}
        titleTemplate={user.metadata.pageTitle}
        hidePrefix={user.metadata.hideDefaultTitlePrefix}
      />
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <Layout {...props} palette={settings.palette} />
    </BaseContextProvider>
  )
}
export default LayoutContainer
