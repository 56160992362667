let anime: any
let $: any
if (typeof document !== `undefined` || typeof window !== `undefined`) {
  $ = require('jquery')
  // eslint-disable-next-line import/newline-after-import
  ;(window as any).jQuery = $
  anime = require('../../../../../static/js/anime.min.js')
}

const initJs = (animateScaleRef: any): null => {
  if (typeof document === `undefined` || typeof window === `undefined`) {
    return null
  }

  if (animateScaleRef) {
    const el = document.querySelector('.hero-3-title .letters')
    if (el && el.textContent) {
      el.innerHTML = el.textContent.replace(/\S/g, "<span class='letter'>$&</span>")
    }

    anime
      .timeline({ loop: true })
      .add({
        targets: '.hero-3-title .letter',
        rotateY: [-90, 0],
        duration: 1500,
        delay: (_: any, i: number) => 45 * i,
      })
      .add({
        targets: '.hero-3-title',
        opacity: 0,
        duration: 1500,
        easing: 'easeOutExpo',
        delay: 500,
      })
  }

  return null
}

export default initJs
